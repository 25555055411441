import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Content } from "../../layout/Content";
import propTypes from "../../../constants/propTypes";
import DetailAndCycleContainer from "./DetailAndCycleContainer";
import Terms from "../Settings/Terms/GlobalTerms";

const Wrapper = styled.div`
  margin-top: 24px;
`;

const EntityDetailPage = ({
  backUrl,
  nextItemRoute,
  lastItemRoute,
  entity,
  onOpenEditModal,
  onOpenDeleteModal,
}) => {
  return (
    <Content>
      <Fragment>
        <DetailAndCycleContainer
          entity={entity}
          backUrl={backUrl}
          nextItemRoute={nextItemRoute}
          lastItemRoute={lastItemRoute}
          onOpenEditModal={onOpenEditModal}
          onOpenDeleteModal={onOpenDeleteModal}
        />
        <Wrapper>
          {entity && (
            <Terms
              termContext="entityId"
              contextValue={entity.id}
              disableSyncGlobalTerms
              disableAddTerm
            />
          )}
        </Wrapper>
      </Fragment>
    </Content>
  );
};

EntityDetailPage.propTypes = {
  entity: propTypes.entity,
  onOpenEditModal: PropTypes.func.isRequired,
  onOpenDeleteModal: PropTypes.func.isRequired,
  backUrl: PropTypes.string.isRequired,
  nextItemRoute: PropTypes.string,
  lastItemRoute: PropTypes.string,
};

export default EntityDetailPage;
