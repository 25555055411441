import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { inputs } from "../../inputs/inputConfigs";
import { getOptionsFromSchema } from "../../inputs/utils";
import TooltipIcon from "../../mui/core/TooltipIcon";
import { WarningOutlined } from "@material-ui/icons";

const Fill = styled.div`
  display: flex;
  flex: 1;
`;

export const NonTaxableLabel = ({ disabledTaxSpecCategory }) => {
  if (!disabledTaxSpecCategory) {
    return "Non-Taxable Spec";
  }

  return (
    <div style={{ display: "flex" }}>
      <TooltipIcon
        Icon={WarningOutlined}
        title="This setting can not be edited as this spec category's tax settings are controlled via the project settings."
      />
      Non-Taxable Spec
    </div>
  );
};
NonTaxableLabel.propTypes = {
  disabledTaxSpecCategory: PropTypes.bool,
};

export default (options, isLabeled, specCategoryKey) => {
  const disabledTaxCheckCategories = getOptionsFromSchema(
    "SpecCategory",
    "nonManagedTaxExemptCategories"
  )();

  const fields = [
    {
      input: {
        ...inputs.isAllowance,
        disabled: isLabeled,
      },
      width: 161,
    },
    {
      input: {
        ...inputs.isPlaceholder,
        disabled: isLabeled,
      },
      width: 168,
    },
  ];

  const disabledTaxSpecCategory = disabledTaxCheckCategories.includes(
    specCategoryKey
  );
  !isLabeled &&
    fields.push({
      input: {
        ...inputs.moreActions,
        options,
      },
      width: 120,
      preventSubmit: true,
    });

  fields.push(
    {
      component: Fill,
    },
    {
      input: {
        ...inputs.nonTaxableCheck,
        disabled: isLabeled || disabledTaxSpecCategory,
        label: (
          <NonTaxableLabel disabledTaxSpecCategory={disabledTaxSpecCategory} />
        ),
      },
      width: "auto",
      direction: "left",
    }
  );

  return fields;
};
